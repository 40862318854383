import classNames from 'classnames'
import { isPast } from 'date-fns'
import React from 'react'
import { Event } from 'src/types'
import { dayOfMonth, monthShort } from 'src/utils/dates'
import Rectangle from '../assets/rectangle.svg'
import * as styles from './event-date-tag.module.scss'

const EventDateTag = ({ event, small }: { event: Event; small?: boolean }) => {
  const date = new Date(event.date)
  const pastEvent = isPast(date)

  return (
    <div
      className={classNames(styles.eventDateTag, {
        [styles.eventDateTagSmall]: small,
        [styles.pastEvent]: pastEvent,
      })}
    >
      <div className={styles.eventDate}>
        <div className={styles.eventDateBg}>
          <Rectangle />
        </div>
        <div className={styles.dateContent}>
          <div className={styles.dateMonth}>{monthShort(date)}</div>
          <div className={styles.dateDay}>{dayOfMonth(date)}</div>
        </div>
      </div>
    </div>
  )
}

export default EventDateTag
