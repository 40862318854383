// extracted by mini-css-extract-plugin
export const eventCard = "event-card-module--eventCard--3-Z2W";
export const eventCardImageContainer = "event-card-module--eventCardImageContainer--3hidr";
export const eventCardDetails = "event-card-module--eventCardDetails--2dUG4";
export const eventImage = "event-card-module--eventImage--lulXW";
export const eventCardTitle = "event-card-module--eventCardTitle--1FBMO";
export const eventCardDetail = "event-card-module--eventCardDetail--3lXuv";
export const eventCardDetailLabel = "event-card-module--eventCardDetailLabel--2pTwO";
export const eventCardLocation = "event-card-module--eventCardLocation--3h2sP";
export const eventCardLocationContainer = "event-card-module--eventCardLocationContainer--1D1Ql";
export const eventCardDetailItem = "event-card-module--eventCardDetailItem--3Rrv4";
export const eventCardSummary = "event-card-module--eventCardSummary--1Qaf8";
export const eventImageActions = "event-card-module--eventImageActions--3Y7SG";
export const eventImageDate = "event-card-module--eventImageDate--2Mq6a";
export const eventImageDateContainer = "event-card-module--eventImageDateContainer--hiZwH";