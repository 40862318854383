import classNames from 'classnames'
import { isFuture } from 'date-fns'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import EventDateTag from 'src/components/event-date-tag'
import LinkArrow from 'src/components/link-arrow'
import { eventPath } from 'src/paths'
import { Event } from 'src/types'
import { dateLong, eventTimeRange } from 'src/utils/dates'
import * as styles from './featured-event.module.scss'
import ResponsiveImage from './responsive-image'

const FeaturedEvent = ({
  uid,
  event,
  eventPage,
}: {
  uid: string
  event: Event
  eventPage?: boolean
}) => {
  const date = new Date(event.date)
  const featureImage = event.feature_image?.thumbnails?.large?.url
    ? event.feature_image.thumbnails?.large
    : event.image?.thumbnails?.large
  const image = eventPage ? event.image?.thumbnails?.large : featureImage
  const hideImageOnEventPage = eventPage && !!event.hide_image_on_event_page
  const futureEvent = isFuture(date)

  return (
    <>
      <div className={styles.eventImageContainer}>
        {!hideImageOnEventPage && !!image?.url && (
          <>
            <EventDateTag event={event} />
            <Link to={eventPath(uid)} className="d-flex">
              <ResponsiveImage image={image} className={styles.eventImage} />
            </Link>
          </>
        )}
      </div>
      {!eventPage && (
        <div className={styles.eventImageDetails}>
          <Row className="flex-grow-1">
            <Col xs={12} md={9}>
              <h6 className="h6-orange-heading">Featured</h6>
              <Link to={eventPath(uid)} className="link-light">
                <RichText render={event.title.raw} />
              </Link>
              <div className={styles.eventSummary}>
                <RichText render={event.summary.raw} />
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className={styles.eventImageActions}>
                <div
                  className={classNames(styles.eventImageDateContainer, { 'mb-0': !futureEvent })}
                >
                  <div className={styles.eventImageDate}>{dateLong(date)}</div>
                  {eventTimeRange(date, event.duration_minutes)}
                </div>
                {futureEvent && event.rsvp_link.url && (
                  <LinkArrow href={event.rsvp_link.url} block={true} textLarge={true}>
                    RSVP
                  </LinkArrow>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default FeaturedEvent
