import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { isFuture } from 'date-fns'
import { graphql, Link, navigate } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React, { useMemo, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { ValueType } from 'react-select'
import EventCard from 'src/components/event-card'
import FeaturedEvent from 'src/components/featured-event'
import { eventsPath, pastEventsPath } from 'src/paths'
import { BbvPageProps, PrismicEventsProps } from 'src/types'
import { sortLabels } from 'src/utils/sort'
import { StringParam, useQueryParam } from 'use-query-params'
import Layout from '../components/layout'
import Select from '../components/select'
import * as styles from './events.module.scss'

export type OptionType = { label: string; value: string }

export const query = graphql`
  query EventsQuery {
    prismicEvents {
      data {
        title {
          raw
          text
        }
      }
    }

    allPrismicEvent(sort: { fields: data___date, order: ASC }) {
      nodes {
        uid
        type
        data {
          title {
            raw
            text
          }
          date
          summary {
            raw
          }
          feature
          image {
            alt
            thumbnails {
              small {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              large {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
          feature_image {
            url
            alt
            dimensions {
              height
              width
            }
          }
          rsvp_link {
            url
          }
          duration_minutes
          location_name
          location_address
          location_city_state_zip
          event_type {
            slug
            document {
              ... on PrismicEventType {
                uid
                data {
                  description {
                    html
                    text
                    raw
                  }
                  name
                }
              }
            }
          }
        }
      }
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
    allPrismicEventType(sort: { fields: data___name }) {
      nodes {
        data {
          name
          description {
            raw
          }
        }
        uid
      }
    }
  }
`

const Events = (props: BbvPageProps<PrismicEventsProps>) => {
  const [showAll, setShowAll] = useState(false)
  const [selectedEventTypeUid, setSelectedEventTypeUid] = useQueryParam('event-type', StringParam)

  const { pathname } = useLocation()

  const isSSR = typeof window === 'undefined'

  const eventsPage = props.data.prismicEvents.data
  const nodes = props.data.allPrismicEvent.nodes

  const past = pathname === pastEventsPath()

  const futureEvents = nodes.filter((e) => isFuture(new Date(e.data.date)))
  const featuredEvent = futureEvents.find((e) => e.data.feature)
  const currentNodes = past
    ? nodes.filter((e) => !isFuture(new Date(e.data.date))).reverse()
    : futureEvents

  const eventTypeCounts = useMemo(() => {
    const counts: Record<string, number> = {}
    currentNodes.forEach((node) => {
      const eventTypeUid = node.data.event_type?.document?.uid
      if (eventTypeUid) {
        counts[eventTypeUid]
          ? (counts[eventTypeUid] = counts[eventTypeUid] + 1)
          : (counts[eventTypeUid] = 1)
      }
    })
    return counts
  }, [currentNodes])

  const selectedEventType = useMemo(
    () => props.data.allPrismicEventType.nodes.find((node) => node.uid === selectedEventTypeUid),
    [props.data.allPrismicEventType.nodes, selectedEventTypeUid]
  )

  if (!nodes.length || isSSR) return null

  const selectOptions = sortLabels(
    props.data.allPrismicEventType.nodes.map((eventType) => {
      const count = eventTypeCounts[eventType.uid] || 0
      return {
        label: `${eventType.data.name} (${count})`,
        value: eventType.uid,
      }
    })
  )
  const filteredCurrentNodes = selectedEventType
    ? currentNodes.filter((node) => node.data.event_type?.document?.uid === selectedEventType.uid)
    : currentNodes

  const visibleNodes = showAll ? filteredCurrentNodes : filteredCurrentNodes.slice(0, 6)

  const onTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, newPath: string) => {
    e.preventDefault()
    const params = new URLSearchParams()
    if (selectedEventTypeUid) params.append('event-type', selectedEventTypeUid)
    const paramsStr = params.toString()
    navigate(`${newPath}${paramsStr.length > 0 ? `?${paramsStr}` : ''}`, {
      state: {
        disableScrollUpdate: true,
      },
    })
  }

  return (
    <Layout title={eventsPage.title.text} pageData={props.data}>
      <div className={classNames('page-container gray-container', styles.eventsPageContainer)}>
        <Container>
          <Row>
            <Col xs={12} md={7}>
              <RichText render={eventsPage.title.raw} />
            </Col>
            <Col xs={12} md={5}>
              {selectOptions.length > 0 && (
                <div className={styles.eventsFilterSelect}>
                  <div className={styles.eventsFilterSelectLabel}>Filter by event type</div>
                  <Select
                    placeholder="All Events"
                    options={selectOptions}
                    value={selectOptions.find((o) => o.value === selectedEventTypeUid) || undefined}
                    onChange={(newSelected: ValueType<OptionType, boolean>) =>
                      setSelectedEventTypeUid((newSelected as OptionType)?.value)
                    }
                    isClearable={true}
                  />
                </div>
              )}
            </Col>
          </Row>

          <hr />

          {!!selectedEventType && (
            <Row className={styles.eventsSelectedEventType}>
              <Col xs={12} md={7}>
                <h4>{selectedEventType.data.name}</h4>
                <RichText render={selectedEventType.data.description.raw} />
              </Col>
            </Row>
          )}

          {typeof featuredEvent !== 'undefined' && (
            <FeaturedEvent uid={featuredEvent.uid} event={featuredEvent.data} />
          )}

          <div className={styles.events}>
            <div className={styles.eventsControls}>
              <div className={styles.eventsControlsButton}>
                <Link
                  to={eventsPath()}
                  onClick={(e) => onTabClick(e, eventsPath())}
                  className={`btn ${past ? 'btn-light' : 'btn-primary'}`}
                >
                  Upcoming Events
                </Link>
              </div>
              <div className={styles.eventsControlsButton}>
                <Link
                  to={pastEventsPath()}
                  onClick={(e) => onTabClick(e, pastEventsPath())}
                  className={`btn ${past ? 'btn-primary' : 'btn-light'}`}
                >
                  Past Events
                </Link>
              </div>
            </div>

            <div className={classNames('h1', styles.eventControlsHeading)}>
              {`${past ? 'Past' : 'Upcoming'} Events`}
            </div>

            <div className={styles.eventCards}>
              <Row>
                {visibleNodes.map((node) => (
                  <Col xs={12} md={4} key={node.uid}>
                    <EventCard uid={node.uid} event={node.data} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>

          {!showAll && filteredCurrentNodes.length > 6 && (
            <>
              <Button
                onClick={() => setShowAll(true)}
                size="lg"
                block={true}
                className="text-uppercase"
              >
                Show More Events
              </Button>
            </>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default withPreview(Events)
