import classNames from 'classnames'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import EventDateTag from 'src/components/event-date-tag'
import { eventPath } from 'src/paths'
import { Event } from 'src/types'
import { dateLong, eventTimeRange } from 'src/utils/dates'
import * as styles from './event-card.module.scss'
import ResponsiveImage from './responsive-image'

export const EventCardDetails = ({ event }: { event: Event }) => {
  const date = new Date(event.date)
  const locations = [
    event.location_name,
    event.location_address,
    event.location_city_state_zip,
  ].filter((l) => l && l.length > 0)
  const locationTitle = locations.join(' ')

  return (
    <div className={styles.eventCardDetails}>
      <div className={styles.eventCardDetail}>
        <div className={styles.eventCardDetailLabel}>Date</div>
        <div className={styles.eventCardDetailItem}>{dateLong(date)}</div>
      </div>
      <div className={styles.eventCardDetail}>
        <div className={styles.eventCardDetailLabel}>Time</div>
        <div className={styles.eventCardDetailItem}>
          {eventTimeRange(date, event.duration_minutes)}
        </div>
      </div>
      {locationTitle.length > 0 && (
        <div className={classNames(styles.eventCardDetail, styles.eventCardLocation)}>
          <div className={styles.eventCardDetailLabel}>Location</div>
          <div className={styles.eventCardLocationContainer} title={locationTitle}>
            {locations.map((location, index) => (
              <div key={index}>
                <div className={styles.eventCardDetailItem}>{location}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const EventCard = ({ uid, event }: { uid: string; event: Event }) => {
  const image = event.image?.thumbnails?.small
  return (
    <div className={styles.eventCard}>
      <Link to={eventPath(uid)} className={styles.eventCardImageContainer}>
        <EventDateTag event={event} small={true} />
        {!!image && <ResponsiveImage image={image} className={styles.eventImage} />}
      </Link>
      <div className={styles.eventCardDetails}>
        <div className={styles.eventCardTitle}>
          <Link to={eventPath(uid)} className="h4 link-dark">
            {event.title.text}
          </Link>
        </div>

        <EventCardDetails event={event} />

        <div className={styles.eventCardSummary}>
          <RichText render={event?.summary?.raw} />
        </div>

        <Link to={eventPath(uid)} className="link-dark link-underline">
          View Event Details
        </Link>
      </div>
    </div>
  )
}

export default EventCard
